(function () {
	'use strict';
	angular.module('awm.app').factory('APIConfigService', APIConfigService);

	APIConfigService.$inject = ['$location'];

	function APIConfigService($location) {
		let debugParam = false,
			googleAPIKey = 'AIzaSyBtshvJxsywSfXfiCzj76kP9aHZA0IQ0-4',
			googleAPIGCKey = 'AIzaSyAus1NdXu4wX4ifbAkK3Gbp7NaQy6ljcCc';

		if ($location.host().indexOf('.local') > 0 || $location.host().indexOf('localhost') >= 0) {
			//debugParam = 'XDEBUG_SESSION_START=VW$';
		}

		return {
			url: getUrl,
			googleAPIKey: googleAPIKey,
			googleAPIGCKey: googleAPIGCKey
		};

		function getUrl(category, id, subcat, params) {
			let url = `_engine/restapi/index.php?scat=${category}`;
			if (id) {
				url += `&id=${id}`;
			}
			if (subcat) {
				url += `&ssub=${subcat}`;
			}
			if (params) {
				for (let x in params) {
					if (params.hasOwnProperty(x)) {
						url += `&${x}=${params[x]}`;
					}
				}
			}
			if (debugParam) {
				url += `&${debugParam}`;
			}

			return url;
		}
	}
})();