(function () {
	'use strict';
	angular.module('awm.app').factory('ContactService', ContactService);

	ContactService.$inject = ['$http', '$location', '$q', 'APIConfigService'];

	function ContactService($http, $location, $q, APIConfigService) {

		return {
			postContact: postContact
		};

		function postContact(contactData) {
			let url = APIConfigService.url('contact');
			return $http.post(url, contactData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for postContact.' + error);
			}
		}
	}
})();
