(function () {
	'use strict';
	angular.module('awm.main').directive('mainComponent', mainComponent);

	function mainComponent() {
		return {
			restrict: 'A',
			controller: mainComponentController,
			controllerAs: 'mc',
			bindToController: true
		};
	}

	mainComponentController.$inject = ['$scope', '$state', '$transitions', 'toastr', 'Utilities'];

	function mainComponentController($scope, $state, $transitions, toastr, Utilities) {
		let self = this;
		self.hello = 'Hello world';
		self.engine = {};
		console.log($state.current);
		$transitions.onSuccess({}, function(transition) {
			self.currentPage = transition.to().name;
			self.engine = $state.current.data;
			console.log(self.engine);
			console.log(
				"Successful Transition from " + transition.from().name +
				" to " + transition.to()
			);
		});
	}
})();
