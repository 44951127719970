(function () {
	'use strict';
	angular.module('awm.app')
	.config(['$stateProvider', '$urlRouterProvider', '$breadcrumbProvider', function ($stateProvider, $urlRouterProvider, $breadcrumbProvider) {
		$urlRouterProvider.otherwise('/');
		$breadcrumbProvider.setOptions({
			prefixStateName: 'app.home',
			includeAbstract: true,
			template: '<li class="breadcrumb-item" ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last || !!step.abstract"><a ng-switch-when="false" href="{{step.ncyBreadcrumbLink}}">{{step.ncyBreadcrumbLabel}}</a><span ng-switch-when="true">{{step.ncyBreadcrumbLabel}}</span></li>'
		});

		$stateProvider.state('app', {
			abstract: true,
			ncyBreadcrumb: {
				label: 'Root',
				skip: true
			}
		})
		.state('app.home', {
			url: '/',
			templateUrl: '/views/home.php',
			data: {
				slider: true,
				hero: null,
				title: 'Movers You Trust',
				desc: 'We offer Residential / Commercial Moving, Packing & Storage. Local and Long Distance. Fast & Reliable Service. Just call 908.862.0777 for a FREE quote.'
			},
			ncyBreadcrumb: {
				label: 'Home',
			},
		})
		.state('app.storage', {
			url: '/storage',
			templateUrl: '/views/storage.php',
			data: {
				slider: false,
				hero: 'storage-brooklyn-01',
				title: 'Storage',
				desc: 'We offer residential and commercial storage solutions. Long and Short term, Large and Small. Convenient locations in NY, NJ. Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Storage',
			},
		}).state('app.packingsupplies', {
			url: '/packing-supplies',
			templateUrl: '/views/packing-supplies.php',
			data: {
				slider: false,
				hero: 'packing-supplies-brooklyn-01',
				title: 'Packing Supplies',
				desc: 'We offer a wide variety of moving supplies: small, medium and large boxes, duct tape, bubble wraps etc. Call 908.862.0777 for a quote!.'
			},
			ncyBreadcrumb: {
				label: 'Packing Supplies',
			},
		}).state('app.packing', {
			url: '/packing',
			templateUrl: '/views/packing.php',
			data: {
				slider: false,
				hero: 'packing-service-brooklyn-01',
				title: 'Packing Service',
				desc: 'We offer professional & reliable packing / unpacking service along with your residential or commercial moving.  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Packing Service',
			},
		}).state('app.specialty', {
			url: '/art-piano-moving',
			templateUrl: '/views/art-piano-moving.php',
			data: {
				slider: false,
				hero: 'art-piano-moving-brooklyn-01',
				title: 'Art / Piano Moving',
				desc: 'We offer professional fine art or piano or any special item moving service. Large trucks, custom packing/wrapping supplies.  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Art / Piano Moving',
			},
		}).state('app.moving', {
			url: '/moving',
			templateUrl: '/views/moving.php',
			data: {
				slider: false,
				hero: 'residential-moving-brooklyn-01',
				title: 'Moving Service',
				desc: 'We offer professional, reliable and affordable moving service. Residential and Commercial, Long Distance and Local.  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Moving Service',
			},
		}).state('app.moving.residential', {
			url: '/residential-moving',
			templateUrl: '/views/residential-moving.php',
			data: {
				slider: false,
				hero: 'residential-moving-brooklyn-01',
				title: 'Residential Moving',
				desc: 'We offer professional, reliable and affordable residential moving service. Homes, Appartments, High-Rises - we do it all!  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Residential Moving',
			},
		}).state('app.moving.office', {
			url: '/office-moving',
			templateUrl: '/views/office-moving.php',
			data: {
				slider: false,
				hero: 'office-moving-brooklyn-01',
				title: 'Commercial / Office Moving',
				desc: 'We offer professional, reliable and affordable commercial / office moving service. Plant or office  - we do it all!  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Office Moving',
			},
		}).state('app.moving.longdistance', {
			url: '/long-distance-moving',
			templateUrl: '/views/long-distance-moving.php',
			data: {
				slider: false,
				hero: 'long-distance-moving-brooklyn-01',
				title: 'Long Distance Moving',
				desc: 'We offer professional, reliable and affordable long distance moving service. Interstate or international moving  - we do it!  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Long Distance Moving',
			},
		}).state('app.moving.local', {
			url: '/local-moving',
			templateUrl: '/views/local-moving.php',
			data: {
				slider: false,
				hero: 'local-moving-brooklyn-01',
				title: 'Local Moving',
				desc: 'We offer professional, reliable and affordable local moving service. We know your neighborhood,  just call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Local Moving',
			},
		}).state('app.testimonials', {
			url: '/moving-testimonials',
			templateUrl: '/views/testimonials.php',
			data: {
				slider: false,
				hero: 'testimonials-moving-brooklyn-01',
				title: 'Testimonials',
				desc: 'Read what our clients say about our residential or commercial moving services. Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Testimonials',
			},
		}).state('app.about', {
			url: '/about-awesome-moving',
			templateUrl: '/views/about.php',
			data: {
				slider: false,
				hero: 'about-moving-brooklyn-01',
				title: 'About Awesome Moving',
				desc: 'Read about us, we offer Residential / Commercial Moving, Packing & Storage. Local and Long Distance. Fast & Reliable Service. Just call 908.862.0777 for a FREE quote.'
			},
			ncyBreadcrumb: {
				label: 'About Awesome Moving Company',
			},
		}).state('app.contact', {
			url: '/contact-awesome-moving',
			templateUrl: '/views/contact.php',
			data: {
				slider: false,
				hero: 'contact-moving-brooklyn-01',
				title: 'Contact Us',
				desc: 'Have some feedback about our Residential / Commercial Moving, Packing & Storage services? Leave it here.'
			},
			ncyBreadcrumb: {
				label: 'Contact Awesome Moving Company',
			},
		}).state('app.guarantee', {
			url: '/guarantee-insurance',
			templateUrl: '/views/guarantee-insurance.php',
			data: {
				slider: false,
				hero: 'contact-moving-brooklyn-01',
				title: 'Guarantee & Insurance',
				desc: 'We offer our guarantee and moving insurance along with your residential or commercial moving.  Call 908.862.0777 for a quote!'
			},
			ncyBreadcrumb: {
				label: 'Guarantee & Insurance',
			},
		});
	}]);
})();