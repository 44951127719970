(function () {
	'use strict';
	angular.module('awm.quote').directive('quoteComponent', quoteComponent);

	function quoteComponent() {
		return {
			restrict: 'E',
			templateUrl: 'src/js/components/quote/quote.php',
			controller: quoteComponentController,
			controllerAs: 'qc',
			bindToController: true
		};
	}

	quoteComponentController.$inject = ['$scope', '$state', 'toastr', 'QuoteService', 'Utilities'];

	function quoteComponentController($scope, $state, toastr, QuoteService, Utilities) {
		let self = this;
		self.calendarOpened = false;

		self.formUI = {};
		self.formUI.regex = Utilities.regex;
		self.formUI.zipMask = '99999?9';
		self.formUI.isError = Utilities.isError;

		self.model = {
			quote: {}
		};

		self.inlineOptions = {
			minDate: new Date(),
			showWeeks: false,
			showButtonBar: false
		};

		self.checkZipMask = checkZipMask;
		self.submitQuote = submitQuote;
		self.getAddress = getAddress;

		function submitQuote() {
			let quoteData = angular.copy(self.model.quote);
			if(self.model.$valid === false) {
				toastr.error('Your quote has not been sent. Please fill all required fields', 'Error');
				return false;
			}

			return QuoteService.postQuote(quoteData).then(function (response) {
				console.log('Quote has been sent. ', quoteData);
				if (response && response.err === false) {
					toastr.success('Your quote request has been sent! You will get your quote within 12 hours. Thank you!', 'Success');
				} else {
					toastr.error('Your quote request has not been sent. Please try again.', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});

		}


		function getAddress(zip, direction) {
			if(!zip) {
				return;
			}
			return QuoteService.getAddress(zip).then(function (response) {
				if(response.error || !response.data) {
					if(direction === 0) {
						self.model.quote.fromAddress = null;
					} else {
						self.model.quote.toAddress = null;
					}
					return false;
				}
				if(direction === 0) {
					self.model.quote.fromAddress = response.data.formatted_address;
				} else {
					self.model.quote.toAddress = response.data.formatted_address;
				}
			}).then(function(response) {
				if(self.model.quote.fromAddress && self.model.quote.toAddress) {
					return QuoteService.getDistance(self.model.quote.fromAddress, self.model.quote.toAddress).then(function (response) {
						console.log(response);
					})
				}
			}).catch(function (error) {
				console.error(error);
			});
		}
		function checkZipMask(zip) {
			let zipCode = zip;
			if (zipCode && zipCode.length > 5) {
				self.formUI.zipMask = '99999-?9?9?9?9';
			} else {
				self.formUI.zipMask = '99999?9';
			}
		}

		let mxDate = new Date(),
			mnDate = new Date(),
			maxDate = new Date(mxDate.setMonth(mxDate.getMonth() + 6)),
			minDate = new Date(mnDate.setDate(mnDate.getDate() + 1));

		self.dt = minDate;
		self.dateOptions = {
			dateDisabled: false,
			formatYear: 'yy',
			maxDate: maxDate,
			maxMode: 'month',
			minDate: minDate,
			startingDay: 0,
			showWeeks: false,
			showButtonBar: false
		};

		self.showCalendar = function () {
			self.calendarOpened = true;
		};

	}
})();
