(function () {
	'use strict';
	angular.module('awm.app').factory('Utilities', Utilities);

	Utilities.$inject = [];

	function Utilities() {

		let regex = {
			email: /(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/,
			decimal: /^\d*\.?\d{1,2}$/
		};

		return {
			regex: regex,
			isObjectEmpty: isObjectEmpty,
			isError: isError,
			isCertainError: isCertainError
		};

		function isObjectEmpty(obj){
			return Object.keys(obj).length === 0;
		}

		function isError(errorObj) {
			return (errorObj.$dirty && !isObjectEmpty(errorObj.$error));
		}

		function isCertainError(errorVal, isDirty) {
			return (errorVal && !isDirty);
		}
	}
})();
