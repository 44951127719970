var brandPrimary = '#2E86AB';
var brandSuccess = '#BFAB25';
var brandInfo = '#5DB8E1';
var brandWarning = '#FFC857';
var brandDanger = '#F25F5C';

var grayDark = '#1F232D';
var gray = '#5C6170';
var grayLight = '#808490';
var grayLighter = '#C8CACF';
var grayLightest = '#f0f3f5';

(function () {
	'use strict';
	angular.module('awm.main', []);
	angular.module('awm.quote', []);
	angular.module('awm.contact', []);
	// angular.module('awm.dashboard', []);
	// angular.module('awm.users', []);
	// angular.module('awm.usergroups', []);
	// angular.module('awm.taxes', []);
	// angular.module('awm.statuses', []);
	// angular.module('awm.actions', []);
	// angular.module('awm.jobtypes', []);
	// angular.module('awm.transactiontypes', []);
	// angular.module('awm.transactions', []);
	// angular.module('awm.instructions', []);
	// angular.module('awm.notifications', []);
	// angular.module('awm.jobs', []);

	angular.module('awm.app', [
		'ngAnimate',
		'ui.router',
		'ui.bootstrap',
		'ncy-angular-breadcrumb',
		'ui.mask',
		'toastr',
		'awm.main',
		'awm.contact',
		'awm.quote'
	])
	.config(['$httpProvider', '$locationProvider', function ($httpProvider, $locationProvider) {
		//$httpProvider.interceptors.push('authHttpInterceptor');
		$locationProvider.html5Mode(true);
	}])
	.run(['$rootScope', '$state', '$stateParams', '$transitions', function ($rootScope, $state, $stateParams, $transitions) {

	}]);
})();
