(function () {
	'use strict';
	angular.module('awm.contact').directive('contactComponent', contactComponent);

	function contactComponent() {
		return {
			restrict: 'E',
			templateUrl: 'src/js/components/contact/contact.php',
			controller: contactComponentController,
			controllerAs: 'cc',
			bindToController: true
		};
	}

	contactComponentController.$inject = ['$scope', '$state', 'toastr', 'ContactService', 'Utilities'];

	function contactComponentController($scope, $state, toastr, ContactService, Utilities) {
		let self = this;

		self.formUI = {};
		self.formUI.regex = Utilities.regex;
		self.formUI.isError = Utilities.isError;

		self.model = {
			contact: {}
		};

		self.submitContact = submitContact;

		function submitContact() {
			let contactData = angular.copy(self.model.contact);
			if (self.model.$valid === false) {
				toastr.error('Your feedback has not been sent. Please fill all required fields', 'Error');
				return false;
			}

			return ContactService.postContact(contactData).then(function (response) {
				console.log('Feedback has been sent. ', contactData);
				if (response && response.err === false) {
					toastr.success('Your feedback has been sent! We will reply you within 24 hours. Thank you!', 'Success');
				} else {
					toastr.error('Your feedback has not been sent. Please try again.', 'Error');
				}
			}).catch(function (error) {
				console.error(error);
			});

		}

	}
})();
