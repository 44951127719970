(function () {
	'use strict';
	angular.module('awm.app').factory('QuoteService', QuoteService);

	QuoteService.$inject = ['$http', '$location', '$q', 'APIConfigService'];

	function QuoteService($http, $location, $q, APIConfigService) {

		return {
			postQuote: postQuote,
			getAddress: getAddress,
			getDistance: getDistance
		};

		function postQuote(quoteData) {
			let url = APIConfigService.url('quote');
			getAddress(quoteData.fromzip);
			return $http.post(url, quoteData)
			.then(function (response) {
				return response.data;
			})
			.catch(onFailed);

			function onFailed(error) {
				console.log('XHR Failed for postQuote.' + error);
			}
		}

		function getDistance(from, to) {
			let url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' + from + '&destinations=' + to + '&units=imperial&key=' + APIConfigService.googleAPIKey;
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				console.log(response);
				if (response.data.status !== 'OK' || !response.data.rows) {
					return { error: true, message: 'Route not found' };
				}
				return { error: false, data: response.data.rows[0] }; // lets return only the first
			}

			function onFailed(error) {
				console.log('XHR Failed for getDistance.',error);
			}

		}

		function getAddress(zip) {
			let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + zip + '&components=postal_code:' + zip + '|country:us&key=' + APIConfigService.googleAPIGCKey;
			return $http.get(url)
			.then(onComplete)
			.catch(onFailed);

			function onComplete(response) {
				if (response.data.status !== 'OK' || !response.data.results) {
					return { error: true, message: 'Address not found' };
				}
				return { error: false, data: response.data.results[0] }; // lets return only the first
			}

			function onFailed(error) {
				console.log('XHR Failed for getDestination.' + error);
			}
		}
	}
})();
